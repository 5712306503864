import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {

  constructor() {
  }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));
  }

  public importAsExcelFile(event){

     /* wire up file reader */
     const reader: FileReader = new FileReader();
     const target: DataTransfer = <DataTransfer>(event.target);

     if (target.files.length !== 1) {
       throw new Error('Cannot use multiple files');
     }
     reader.readAsBinaryString(target.files[0]);
     
     reader.onload = (e: any) => {
       /* create workbook */
       console.log("target",e.target)
       const binarystr: string = e.target.result;
       const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
 
       /* selected the first sheet */
      console.log(wb)
       const wsname: string = wb.SheetNames[0];
       const ws: XLSX.WorkSheet = wb.Sheets[wsname];
       /* save data */
       const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        console.log(data)
        return data
      };
      return reader
  }

  
}