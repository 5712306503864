import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../services/excel.service';
import { AccesoService } from '../services/acceso.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-generateexcel',
  templateUrl: './generateexcel.component.html',
  styleUrls: ['./generateexcel.component.css'],
})
export class GenerateexcelComponent implements OnInit {
  participantes: any =[];
  inicio=false
  pin=""
  correcto: boolean = false;
  mensaje: string ="";
  constructor(
    private excelService: ExcelService,
    public usersService: AccesoService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.usersService.getPacientesOrd().subscribe((usuarios)=>{
      usuarios.forEach((usuario)=>{
        let usu = usuario.payload.doc.data()
        this.participantes.push(usu)
      })
    })
    setTimeout(() => {
      this.inicio=true
    }, 2000);
  }

  comprobarPin(){
    if(this.pin=="4545"){
      this.correcto=true
    }
    else{
      this.correcto=false
      this.mensaje="PIN ERRONEO"
    }
  }

  generarExcel() {
    var listaDatos = new Array();
    this.participantes.forEach((usuario) => {
      let fecha = new Date(usuario.horaConexion.seconds*1000)
      let horaFormato = this.datePipe.transform(fecha,"HH:mm")
      let diaFormato = this.datePipe.transform(fecha,"dd/MM/yyyy")
      var datos = {
        nombre:usuario.nombre,
        apellidos:usuario.apellidos,
        dia:diaFormato,
        hora:horaFormato,
        id: usuario.idEstudiante,
        colegio:usuario.colegio,
        provincia:usuario.provincia,
        pregunta:usuario.motivo,
        atendido:usuario.check,
        familiares:usuario.conPadres
      };
      listaDatos.push(datos);
    });
    this.excelService.exportAsExcelFile(listaDatos, 'Preguntas_Usuarios');
  }
}
