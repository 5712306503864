
export const environment = {
  production: true,
  backend: {
    baseURL: "https://admin.mygofacing.com/api"
  },
    firebase: {
      apiKey: "AIzaSyBebN4dlfvW_5RUGVGp5kYZ0aaW92wrWjQ",
      authDomain: "salaespera-mygofacing.firebaseapp.com",
      projectId: "salaespera-mygofacing",
      storageBucket: "salaespera-mygofacing.appspot.com",
      messagingSenderId: "364543454064",
      appId: "1:364543454064:web:f92d356e6dedf853c47db5",
      measurementId: "G-1CS2R8TSLF"
    }, 
};
