// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: {
    baseURL: "https://admin.mygofacing.com/api"
  },
    firebase: {
      apiKey: "AIzaSyBebN4dlfvW_5RUGVGp5kYZ0aaW92wrWjQ",
      authDomain: "salaespera-mygofacing.firebaseapp.com",
      projectId: "salaespera-mygofacing",
      storageBucket: "salaespera-mygofacing.appspot.com",
      messagingSenderId: "364543454064",
      appId: "1:364543454064:web:f92d356e6dedf853c47db5",
      measurementId: "G-1CS2R8TSLF"
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
