<div class="containerGeneral">
  <div class="logoSala">
    <img id="logoGo" src="assets/media/img/academica.png" alt="logo" />
    <div class="logout">
      <img
        (click)="logout()"
        title="logout"
        src="assets/media/img/logout.svg"
        alt="log out"
      />
      <a
        href="https://in2.thecampus.education/webapp3/#/?conference={{
          sala
        }}&name={{ nombre }}&pin={{ pin }}&role=host&callType=video"
        target="_blank"
        >Ir a mi sala</a
      >
      <!-- <a *ngIf="!preguntas"
            (click)="getPreguntas()"
          >Ver preguntas</a
        >
        <a *ngIf="preguntas"
            (click)="salirPreguntas()"
          >Quitar preguntas</a
        > -->
    </div>
  </div>
  <div class="datos">
    <div class="title">
      <img src="assets/media/img/espera.svg" alt="menu" />
      <h1 translate>Usuarios</h1>
      <img
        (click)="reload()"
        title="recargar sala de espera"
        src="assets/media/img/reload.svg"
        alt="reload"
      />
    </div>
    <div class="containerP">
      <div class="pacientes">
        <div *ngFor="let item of usuarios; let index = index">
          <app-boxsala
            [item]="item"
            (itemDetail)="getInfo($event)"
          ></app-boxsala>
        </div>
      </div>
      <img id="linea" src="assets/media/img/lineaSeparadora.svg" />
    </div>
  </div>
</div>

<div *ngIf="info == true" class="infoContainer">
  <div class="nombre">
    <h2>{{ userInfo.nombre }} {{ userInfo.apellidos }}</h2>
  </div>
  <div class="dato">
    <p><b>ID estudiante:</b> {{ userInfo.idEstudiante }}</p>
    <hr style="width: 100%" />
    <p><b>Colegio:</b> {{ userInfo.colegio }}</p>
    <p><b>Provincia:</b> {{ userInfo.provincia }}</p>
  </div>
  <hr style="width: 100%" />
  <p><b>Pregunta:</b></p>
  <div class="motivo">
    <p>{{ userInfo.motivo }}</p>
  </div>
  <div class="botones">
    <div *ngIf="!userInfo.check" class="trans">
      <button (click)="transferOperadora()">Transferir a mi sala</button>
    </div>
    <div *ngIf="userInfo.check" class="trans">
      <button (click)="transferFinal()">Transferir a la conferencia</button>
    </div>
  </div>
</div>

<div *ngIf="preguntas == true" class="infoContainer2">
  <div class="pregunta">
    <p class="horaUsuario">Hora</p>
    <p class="nombreUsuario">Nombre</p>
    <p class="colegioUsuario">Colegio</p>
    <p class="preguntaUsuario">Pregunta</p>
  </div>
  <hr />
  <div class="preguntas">
    <div class="pregunta" *ngFor="let item of participantesPreguntas">
      <p class="horaUsuario">{{  (item.horaConexion.seconds*1000) | date:'hh:mm'}}</p>
      <p class="nombreUsuario">{{ item.nombre }} {{ item.apellidos }}</p>
      <p class="colegioUsuario">{{ item.colegio }}</p>
      <p class="preguntaUsuario">{{ item.motivo }}</p>
      <br>
      <br>
    </div>
  </div>
</div>

