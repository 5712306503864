import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccesoService } from '../services/acceso.service';
import { AuthenticationService } from '../services/authentication-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email:any=null;
  password:any=null;
  userdata:any ;

  constructor(
    private userService: AccesoService,
    private authenticationService: AuthenticationService,
    private router: Router
    
  ) {}

  ngOnInit(): void {
  }
  signIn() {

    this.authenticationService
      .loginWithEmail(this.email, this.password)
      .then((data) => {
   
        let opt = data.operationType;
     

        this.userService.getUser(data.user?.uid).subscribe((ddata:any) => {

          if (ddata[0]) {
            this.userdata = ddata[0].payload.doc.data();
            this.userService.setUserinfo(ddata[0]);
            this.router.navigate(['recepcion']);
          }
        });
      })
      .catch((error) => {
        alert('Datos equivocados o el usuario no existe.');
      });
  }

}
