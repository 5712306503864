<!-- //Molde de Paciente// -->
<div *ngIf="!item.check" class="paciente" (click)="getInfo()">
    <img id="añadirPaciente" src="assets/media/img/espera.svg" alt="añadir Paciente" (click)="getInfo()"> 
    <p><b>{{item.nombre |titlecase}} {{item.apellidos |titlecase}}</b></p>
    <p>{{hora | date:'HH:mm'}}</p>
    <!-- <p></p> -->
</div>
<div *ngIf="item.check" class="paciente2" (click)="getInfo()">
    <img id="añadirPaciente" src="assets/media/img/espera.svg" alt="añadir Paciente" (click)="getInfo()"> 
    <p><b>{{item.nombre |titlecase}} {{item.apellidos |titlecase}}</b></p>
    <!-- <p>{{hora | date:'HH:mm'}}</p> -->
    <img src="/assets/media/img/ok.svg" alt="ok logo" />
    <!-- <p></p> -->
</div>
