import { Component, OnInit } from '@angular/core';
import { AccesoService } from '../services/acceso.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {
  nombre: string = null;
  apellidos: string = null;
  fechanac: any = null;
  id: string = null;
  motivo: string = null;
  response: string = null;
  error: boolean = false;
  registrado: boolean = false;
  form: boolean = true;
  colegio = '';
  provincia = '';
  idEstudiante = '';
  conPadres="";

  pacientes: Array<any>;
  idSala = 'b2RYkEaTJR8sXgGewzwl';
  constructor(private _pacientes: AccesoService) {}

  ngOnInit(): void {
    this.getPacientes();
  }

  getPacientes() {
    var idSala = this.idSala;
    this.pacientes = new Array();
    this._pacientes.getPacientes().subscribe((pacientes) => {
      this.pacientes.splice(0, this.pacientes.length);
      pacientes.forEach((paciente) => {
        this.pacientes.push(paciente.payload.doc.data());
      });
      // console.log(this.pacientes);
    });
  }

  createPaciente() {
    if (this.nombre === null || this.nombre === '') {
      this.response = 'Debe escribir su nombre';
      this.error = true;
      return;
    }
    if (this.apellidos === null || this.apellidos === '') {
      this.response = 'Debe escribir sus apellidos';
      this.error = true;
      return;
    }
    if (this.idEstudiante == null || this.idEstudiante == '') {
      this.response = 'Debe introducir el id del estudiante';
      this.error = true;
      return;
    }
    if (this.colegio === null || this.colegio === '') {
      this.response = 'Debe escribir el nombre del colegio';
      this.error = true;
      return;
    }
    if (this.provincia === null || this.provincia === '') {
      this.response = 'Debe escribir la provincia';
      this.error = true;
      return;
    }

    if (this.conPadres === null || this.conPadres === '') {
      this.response = 'Debe indicar si esta participando con algun familiar';
      this.error = true;
      return;
    }
    if (this.motivo === null || this.motivo === '') {
      this.response = 'Debe introducir alguna pregunta';
      this.error = true;
      return;
    }

    let hora = new Date();
    const paciente = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      id:
        this.nombre.toLowerCase() + Math.floor(100000 + Math.random() * 900000),
      motivo: this.motivo,
      horaConexion: hora,
      colegio: this.colegio,
      provincia: this.provincia,
      check: false,
      idEstudiante: this.idEstudiante,
      conPadres:this.conPadres
    };
    var idSala = this.idSala;
    this._pacientes.createPaciente(paciente).catch((error) => {
      this.error = true;
      this.response = 'No se pudo crear el usuario';
    });
    this.registrado = true;
    this.form = false;
    let url =
      'https://in2.thecampus.education/webapp3/#/?conference=recepcion&name=' +
      paciente.id +
      '&callType=video&role=guest';
    setTimeout(() => {
      window.location.href = url;
      this.registrado = false;
      this.form = true;
    }, 4500);
  }

  createPacienteSinVideo() {
    if (this.nombre === null || this.nombre === '') {
      this.response = 'Debe escribir su nombre';
      this.error = true;
      return;
    }
    if (this.apellidos === null || this.apellidos === '') {
      this.response = 'Debe escribir sus apellidos';
      this.error = true;
      return;
    }
    if (this.idEstudiante == null || this.idEstudiante == '') {
      this.response = 'Debe introducir el id del estudiante';
      this.error = true;
      return;
    }
    if (this.colegio === null || this.colegio === '') {
      this.response = 'Debe escribir el nombre del colegio';
      this.error = true;
      return;
    }
    if (this.provincia === null || this.provincia === '') {
      this.response = 'Debe escribir la provincia';
      this.error = true;
      return;
    }
    
    if (this.conPadres === null || this.conPadres === '') {
      this.response = 'Debe indicar si esta participando con algun familiar';
      this.error = true;
      return;
    }

    if (this.motivo === null || this.motivo === '') {
      this.response = 'Debe introducir alguna pregunta';
      this.error = true;
      return;
    }

    let hora = new Date();
    const paciente = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      id:
        this.nombre.toLowerCase() + Math.floor(100000 + Math.random() * 900000),
      motivo: this.motivo,
      horaConexion: hora,
      colegio: this.colegio,
      provincia: this.provincia,
      check: false,
      idEstudiante: this.idEstudiante,
      conPadres:this.conPadres
    };
    this._pacientes
      .createPaciente(paciente)
      .then(() => {
        this.response = 'Pregunta enviada';
        this.nombre = '';
        this.apellidos = '';
        (this.motivo = ''), (this.colegio = '');
        this.provincia = '';
        this.idEstudiante = '';
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.error = true;
        this.response = 'No se pudo crear el usuario';
      });
  }
}
