import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Paciente } from '../interfaces/paciente';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AccesoService {
  userdata: any;
  datosDeUsuario = new Subject();
  constructor(
    private firestore: AngularFirestore,
    private db: AngularFireDatabase
  ) {}

  getPacientes() {
    return this.firestore.collection('salaespera').doc("b2RYkEaTJR8sXgGewzwl").collection('usuarios').snapshotChanges();
  }
  getPacientesOrd() {
    return this.firestore.collection('salaespera').doc("b2RYkEaTJR8sXgGewzwl").collection('usuarios', (ref) => ref.orderBy('horaConexion', 'asc')).snapshotChanges();
  }
  getPacientesOrdDes() {
    return this.firestore.collection('salaespera').doc("b2RYkEaTJR8sXgGewzwl").collection('usuarios', (ref) => ref.orderBy('horaConexion', 'desc')).snapshotChanges();
  }

  getPaciente(idUser) {
    return this.firestore
      .collection('salaespera')
      .doc('b2RYkEaTJR8sXgGewzwl')
      .collection('usuarios', (ref) => ref.where('id', '==', idUser))
      .get();
  }
  createPaciente(paciente: Paciente) {
    return this.firestore.collection('salaespera').doc("b2RYkEaTJR8sXgGewzwl").collection('usuarios').add(paciente)
  }
  getUserById(uid:any) {
    return this.firestore.collection('salaespera').doc("b2RYkEaTJR8sXgGewzwl").collection('usuarios', ref => ref.where('uid', '==' , uid)).snapshotChanges();
  }

  getUser(uid:any) {
    return this.firestore.collection('usuarios', ref => ref.where('uid', '==' , uid)).snapshotChanges();
  }
  setUserinfo(data:any) {
    this.userdata = data;
    this.datosDeUsuario.next(data);
  }
  getUserinfodata() {
    return this.userdata;
}

  editPaciente(user: any, uiduserdatabase) {
    return this.firestore
      .collection('salaespera')
      .doc('b2RYkEaTJR8sXgGewzwl')
      .collection('usuarios')
      .doc(uiduserdatabase)
      .update(user);
  }

  deletePaciente(uid) {
    return this.firestore
      .collection('salaespera')
      .doc('b2RYkEaTJR8sXgGewzwl')
      .collection('usuarios')
      .doc(uid)
      .delete();
  }
 
}
