<div class="pinBox" *ngIf="!correcto">
  <div class="pin">
    <p>Introduce pin</p>
    <input type="text" name="" id="" [(ngModel)]="pin" />
    <button (click)="comprobarPin()">Comprobar pin</button>
    <p style="margin-top: 15px; color: red; background-color: white; border-radius: 5px; width: 15vw; text-align: center;"  > <b> {{mensaje}} </b></p>
  </div>
</div>

<div class="principal" *ngIf="correcto">
  <div class="pin" *ngIf="!inicio">
    <p>Espere por favor</p>
  </div>
  <div class="pin" *ngIf="inicio">
    <p>GENERAR EXCEL CON LOS DATOS</p>
    <button (click)="generarExcel()" >Descargar</button>
  </div>
</div>
