import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ApirestService } from '../services/apirest.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccesoService } from '../services/acceso.service';

import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase';
import { FunctionCall } from '@angular/compiler';
import { AuthenticationService } from '../services/authentication-service.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-salaespera',
  templateUrl: './salaespera.component.html',
  styleUrls: ['./salaespera.component.css'],
})
export class SalaesperaComponent implements OnInit {
  userInfo: any;
  participantes: any;
  info: boolean = false;
  preguntas: boolean = true;
  usuarios: any;
  userUid: any;
  mainUserIdDb: string;
  datosUsuario: firebase.default.firestore.DocumentData;
  sala: string = '';
  nombre: any;
  pin: any = '';
  hora: Date;
  primeraVez: boolean = true;
  participantesPreguntas=[]
  constructor(
    public api: ApirestService,
    private http: HttpClient,
    public usersService: AccesoService,
    private functions: AngularFireFunctions,
    private authService: AuthenticationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.usersService.getUserinfodata();

    if (Userdata === undefined) {
      this.authService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this.usersService.getUser(this.userUid).subscribe((userdata) => {
            this.mainUserIdDb = userdata[0].payload.doc.id;
            this.datosUsuario = userdata[0].payload.doc.data();
            this.sala = this.datosUsuario.sala;
            this.nombre = this.datosUsuario.nombre;
            this.pin = this.datosUsuario.pin;
            this.getPacientes();
            this.getPreguntas()
          });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.mainUserIdDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.sala = this.datosUsuario.sala;
      this.nombre = this.datosUsuario.nombre;
      this.pin = this.datosUsuario.pin;
      this.getPacientes();
      this.getPreguntas()
    }
  }

  getPacientes = async () => {
    this.participantes = new Array();
    this.usuarios = new Array();

    const url =
      'https://us-central1-salaespera-mygofacing.cloudfunctions.net/app/Pacientes';
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        this.usuarios = [];

        for (let index = 0; index < data.objects.length; index++) {
          let element = data.objects[index];
          this.participantes.push(element);

          this.usersService
            .getPaciente(element.participant_alias)
            .subscribe((dato) => {
              if (this.usuarios.length < data.objects.length) {
                var usuar = dato.docs[0];
                if (usuar) {
                  var usu = usuar.data();
                  usu['idVideo'] = element.id;
                  usu['idBBDD'] = usuar.id;
                  this.usersService.editPaciente(usu, usuar.id);
                  this.usuarios.push(usu);
                  // console.log(usu['idVideo']);
                }
              }
            });
        }

        // data.objects.forEach((element) => {

        // });
      });
  };

  getInfo(event) {
    this.info = true;
    // this.preguntas = false;
    var horaV = new Date(event.horaConexion.seconds * 1000);

    this.hora = horaV;
    this.userInfo = event;
    console.log(this.userInfo);
  }

  getPreguntas(){
    this.info = false;
    this.preguntas = true;
    this.usersService.getPacientesOrdDes().subscribe((usuarios)=>{
      this.participantesPreguntas=[]
      usuarios.forEach((usuario)=>{
        let usu = usuario.payload.doc.data()
        this.participantesPreguntas.push(usu)
      })
    })
  }
  salirPreguntas(){
    this.preguntas=false
  }

  transferFinal() {
    console.log(this.userInfo);
    let id = this.userInfo.idVideo;
    const dataTrans = {
      participant_id: id,
      conference_alias: 'auaw',
      role: 'guest',
    };
    const url =
      'https://us-central1-salaespera-mygofacing.cloudfunctions.net/app/Transferencia';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(dataTrans),
    }).then((res) => {
      console.log('res', res);
      let nombre = this.userInfo.nombre + ' ' + this.userInfo.apellidos;
      var indice = this.usuarios.indexOf(this.userInfo); // obtenemos el indice
      this.usuarios.splice(indice, 1); // 1 es la cantidad de elemento a eliminar
      // this.usersService.deletePaciente(this.userInfo.idBBDD);
      this.info = false;
      window.location.reload();
    });
  }

  quitarInfo(){
    this.info = false;
  }

  transferOperadora() {
    let id = this.userInfo.idVideo;
    const dataTrans = {
      participant_id: id,
      conference_alias: this.sala,
      role: 'guest',
    };
    const url =
      'https://us-central1-salaespera-mygofacing.cloudfunctions.net/app/Transferencia';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(dataTrans),
    }).then((res) => {
      console.log(res);
      var indice = this.usuarios.indexOf(this.userInfo); // obtenemos el indice
      this.usuarios.splice(indice, 1); // 1 es la cantidad de elemento a eliminar
      this.userInfo.check = true;
      this.usersService
        .editPaciente(this.userInfo, this.userInfo.idBBDD)
        .then(() => {
          window.location.reload();
        });
      this.info = false;
    });
  }

  reload() {
    window.location.reload();
  }
  logout() {
    this.authService.logOut();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
