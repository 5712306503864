
<div class="containerGeneral">
    <div>
        <img id="logoGo" src="assets/media/img/academica.png" alt="logo">
    </div>
    <div class="login">
        <h1>Iniciar sesión</h1>
        <div class="loginU">
            <input 
            type="text" 
            [(ngModel)]="email"
            placeholder="Nombre de Usuario">
        </div>

        <div class="loginP">
            <input 
            [(ngModel)]="password"
            type="password"
            placeholder="Contraseña"> 
        </div>

        <button translate (click)="signIn()" (keyup.enter)="signIn()">Acceder</button>
    
    </div>
</div>
