import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-boxsala',
  templateUrl: './boxsala.component.html',
  styleUrls: ['./boxsala.component.css']
})
export class BoxsalaComponent implements OnInit {

  @Output() itemDetail = new EventEmitter<any[]>();
  @Input() item
  info:boolean =false;
  hora: Date;

  constructor() { }

  ngOnInit(): void {
    var horaV = new Date(this.item.horaConexion.seconds*1000  )
    this.hora=horaV
  }
  getInfo(){
    this.info=true;
    this.itemDetail.emit(this.item);
  }
}

